import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';

// import PageViewsChart from '../components/PageViewsChart';

const trailingDays = [
    1, 2, 3, 4, 5, 6, 7
];

// TODO - make green if renewed or updated today.
// Make yellow if not, red if not updated for more than a day.
const AOCard = ({ user_id, user_name }) => {


    const [aos, setAOs] = useState([]);
    const [error, setError] = useState(null);

    console.log("USER ID IS " + user_id);
    console.log("USER NAME IS " + user_name);

    useEffect(() => {
        const fetchAOs = async () => {
            try {
                const url = 'https://lifeboard-api.oswalt.workers.dev/asksandoffers/' + user_id;
                console.log("url is " + url);
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error('Failed to fetch asks');
                }
                const result = await response.json();
                setAOs(result[0]);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchAOs();
    }, [user_id]);

    if (error) {
        // return <div>Error: {error}</div>;
        console.log("Error: " + error);
    }

    console.log("CARD POOP")
    console.log(aos)

    var cdate = new Date(aos.created * 1000);

    var cdate_str = "";
    try {
        cdate_str = cdate.toISOString();
    } catch (err) {
    }

    var now = new Date(Date.now());

    let backgroundColor = 'unset';
    if (cdate.getDate() < now.getDate()) {
        backgroundColor = 'darkyellow';
    }
    if (cdate.getDate() < now.getDate() - 1) {
        backgroundColor = 'darkred';
    }

    return (
        <Card>
            <CardHeader sx={{ backgroundColor: backgroundColor }}
                avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        {String(user_name).toUpperCase().substring(0, 1)}
                    </Avatar>
                }
                action={
                    <IconButton aria-label="settings">
                        <SaveIcon
                            onClick={() => {
                                alert('TODO - implement saving functionality');
                            }}
                        />
                    </IconButton>
                }
                title={user_name}
                subheader={cdate_str}
            />
            {/* <CardContent>
                <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>Asks</Typography>
                <Typography variant="body2">
                    <br />
                    <p>
                        <ul>
                            {row.asks?.map((ask, index) => (
                                <li key={index}>{ask}</li>
                            ))}
                        </ul>
                    </p>
                </Typography>

                <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>Offers</Typography>
                <Typography variant="body2">
                    <br />
                    <p>
                        <ul>
                            {row.offers?.map((offer, index) => (
                                <li key={index}>{offer}</li>
                            ))}
                        </ul>
                    </p>
                </Typography>
            </CardContent> */}

            <Grid
                container
                spacing={2} // Adjust spacing between items
                // columnSpacing={2}
                sx={{
                    width: "100%",
                    pl: 2,
                    pb: 2,
                    // display: "grid",
                    gridTemplateColumns: "repeat(1, 1fr)",
                    gridTemplateRows: "repeat(2, auto)",
                }}
            >

                <Grid item xs={12} key="foo">
                    <TextField
                        id="outlined-multiline-static"
                        label="Asks"
                        multiline
                        rows={5}
                        defaultValue={aos.asks_content}
                        variant="outlined"
                        size="large"
                        InputProps={{
                            style: { fontSize: 13 }, // Adjust input text size
                        }}
                        InputLabelProps={{
                            shrink: true, // Keeps label at the top
                            style: { fontSize: 14 }, // Optional: Adjust label font size
                        }}
                        sx={{
                            width: "100%",
                        }}
                    />

                </Grid>
                <Grid item xs={12} key="foo">
                    <TextField
                        id="outlined-multiline-static"
                        label="Offers"
                        multiline
                        rows={5}
                        defaultValue={aos.offers_content}
                        variant="outlined"
                        size="large"
                        InputProps={{
                            style: { fontSize: 13 }, // Adjust input text size
                        }}
                        InputLabelProps={{
                            shrink: true, // Keeps label at the top
                            style: { fontSize: 14 }, // Optional: Adjust label font size
                        }}
                        sx={{
                            width: "100%",
                        }}
                    />
                </Grid>
            </Grid>

            {/* <CardActions disableSpacing>
                <Button size="small">Repeat for today</Button>
                <Button size="small">Create new</Button>
            </CardActions> */}
        </Card>
    );
}

const Routines = () => {
    return (
        <Card>
            <CardContent>


                <Grid
                    container
                    spacing={2} // Adjust spacing between items
                    sx={{
                        width: "100%",
                        display: "grid",
                        gridTemplateColumns: "repeat(1, 1fr)",
                        gridTemplateRows: "repeat(2, auto)",
                    }}
                >


                    {/* // Contains header row*/}
                    <Grid item xs={12} key="foo">
                        <Grid
                            container
                            spacing={2} // Adjust spacing between items
                            sx={{
                                width: "100%",
                                display: "grid",
                                gridTemplateColumns: "repeat(8, 1fr)",
                                gridTemplateRows: "repeat(2, auto)",
                            }}
                        >
                            <Grid item xs={5} key="foo">
                                <Typography variant="h5" component="div">
                                    Habit Tracking
                                </Typography>
                            </Grid>
                            {trailingDays.map((row, index) => (
                                <Grid item xs={1} key="foo">
                                    <p>{index} days ago</p>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>

                    {/* // Contains user row*/}
                    <Grid item xs={12} key="foo">
                        <Grid
                            container
                            spacing={2} // Adjust spacing between items
                            sx={{
                                width: "100%",
                                display: "grid",
                                gridTemplateColumns: "repeat(8, 1fr)",
                                gridTemplateRows: "repeat(2, auto)",
                            }}
                        >
                            <Grid item xs={5} key="foo">
                                Jamie
                            </Grid>
                            {trailingDays.map((row, index) => (
                                <Grid item xs={1} key="foo">
                                    <CalendarTodayIcon sx={{ fontSize: 80 }} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    {/* // Contains user row*/}
                    <Grid item xs={12} key="foo">
                        <Grid
                            container
                            spacing={2} // Adjust spacing between items
                            sx={{
                                width: "100%",
                                display: "grid",
                                gridTemplateColumns: "repeat(8, 1fr)",
                                gridTemplateRows: "repeat(2, auto)",
                            }}
                        >
                            <Grid item xs={5} key="foo">
                                <p md={6}>Matt</p>
                            </Grid>
                            {trailingDays.map((row, index) => (
                                <Grid item xs={1} key="foo">
                                    <CalendarTodayIcon sx={{ fontSize: 80 }} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>

                </Grid>

            </CardContent>
        </Card >
    );
}



const LifeboardPage = () => {
    // const [aos, setAOs] = useState([]);
    // const [error, setError] = useState(null);

    // useEffect(() => {
    //     const fetchAOs = async () => {
    //         try {
    //             const response = await fetch('https://lifeboard-api.oswalt.workers.dev/users');
    //             if (!response.ok) {
    //                 throw new Error('Failed to fetch asks');
    //             }
    //             const result = await response.json();
    //             setAOs(result);
    //         } catch (err) {
    //             setError(err.message);
    //         }
    //     };

    //     fetchAOs();
    // }, []);

    // if (error) {
    //     // return <div>Error: {error}</div>;
    //     console.log("Error: " + { error });
    // }

    // var jamieCard = {}
    // var mattCard = {}

    // // THis is obviopusly horrible and should be fixed
    // aos.forEach((element, index, array) => {

    //     if (index === 0 && element.user === 1) {
    //         mattCard.askContent = element.content
    //         mattCard.user = "Matt"
    //         mattCard.created = element.created
    //     }

    //     if (index === 0 && element.user === 2) {
    //         jamieCard.askContent = element.content
    //         jamieCard.user = "Jamie"
    //         jamieCard.created = element.created
    //     }

    //     if (element.user === 1 && mattCard.created < element.created) {
    //         mattCard.askContent = element.content
    //         mattCard.created = element.created
    //     }

    //     if (element.user === 1 && jamieCard.created < element.created) {
    //         jamieCard.askContent = element.content
    //         jamieCard.created = element.created
    //     }
    // });


    // TODO - fetch these from users API
    var users = [
        [1, "Matt"],
        [2, "Jamie"]
    ];

    // TODO - iterate through asks, getting the most recent one for each user.
    // Then, pass that result to the card below

    return (
        <React.Fragment>
            <Grid
                container
                spacing={2} // Adjust spacing between items
                sx={{
                    width: "100%", // Ensures it does not exceed the parent width
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)", // Two columns
                    gridTemplateRows: "repeat(1, auto)", // Exactly one rows
                }}
            >
                {users.map(([user_id, user_name], index) => (
                    <Grid item xs={12} key={index}>
                        <Paper elevation={3}>
                            <AOCard user_id={user_id} user_name={user_name} />
                        </Paper>
                    </Grid>
                ))}

            </Grid>

            <Grid
                container
                spacing={2} // Adjust spacing between items
                sx={{
                    width: "100%", // Ensures it does not exceed the parent width
                    display: "grid",
                    gridTemplateColumns: "repeat(1, 1fr)",
                    gridTemplateRows: "repeat(1, auto)",
                }}
            >

                <Grid item xs={12} key="foo">
                    <Paper elevation={3}>
                        <Routines />
                    </Paper>
                </Grid>

            </Grid>

        </React.Fragment>
    );
};

export default LifeboardPage;