import * as React from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import getDashboardTheme from './getDashboardTheme';
import Copyright from './internals/components/Copyright';
import Navbar from './components/Navbar';
// import Home from './pages'
// import RecordPage from './pages/record'
// import ReportsPage from './pages/reports'
import LifeboardPage from './pages/lifeboard'
import {
  MemoryRouter,
} from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';

// function CurrentRoute() {
//   const location = useLocation();

//   return (
//     <Typography variant="body2" sx={{ pb: 2 }} color="text.secondary">
//       Current route: {location.pathname}
//     </Typography>
//   );
// }


function Router(props) {
  const { children } = props;
  if (typeof window === 'undefined') {
    return <StaticRouter location="/drafts">{children}</StaticRouter>;
  }

  return (
    <MemoryRouter initialEntries={['/drafts']} initialIndex={0}>
      {children}
    </MemoryRouter>
  );
}


export default function ExampleDashboard() {
  const [mode, setMode] = React.useState('dark');
  const [showCustomTheme] = React.useState(false);
  const dashboardTheme = createTheme(getDashboardTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  // const toggleCustomTheme = () => {
  //   setShowCustomTheme((prev) => !prev);
  // };

  return (
    <ThemeProvider theme={showCustomTheme ? dashboardTheme : defaultTheme}>
      <CssBaseline />
      <Router>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex' }}>
            <Navbar mode={mode} toggleColorMode={toggleColorMode} />
            <Box
              component="main"
              sx={{
                backgroundColor: 'background.default',
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
              }}
            >
              {/* Main content */}
              <Container
                maxWidth="xl"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  py: { xs: 14, sm: 16 },
                  gap: 2,
                }}
              >
                <LifeboardPage />
                {/* <Header /> */}
                {/* <MainGrid /> */}
                {/* <Routes> */}
                {/* <Route path="*" element={<CurrentRoute />} /> */}
                {/* <Route path="/" element={<LifeboardPage />} /> */}
                {/* </Routes> */}
                <Copyright sx={{ my: 4 }} />
              </Container>
            </Box>
            {/* <ToggleCustomTheme
              showCustomTheme={showCustomTheme}
              toggleCustomTheme={toggleCustomTheme}
            /> */}
          </Box>
        </Box>
      </Router>

    </ThemeProvider>
  );
}
